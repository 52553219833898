/*
  Defualt (or Base) CSS file for all pages/components
  Author: Andrew Pickard, AO 10/4/22
*/

/*
 * ================================================
 *                 SIDEBAR STYLING
 * ================================================
 */


a {
  text-decoration: none;
}

#sidebar {
  max-width: 300px;
  height: 100%;
  position: fixed;
  background-color: #333333;
  padding-top: 20px;
  transition: width .2s;
}

#logo {
  display: block;
  margin: auto;
  margin-bottom: 4%;
  width: 80%;
}

.sidebar_link:hover {
  background-color: white;
  color: black;
}

.sidebar_link {
  height: 50px;
  width: 100%;
  padding-top: 10px;
  padding-left: 10%;
  color: white;
  float: left;
}

.nav-link i {
  margin-right: 32.5px;
}

/*
 * ================================================
 *                   TOPBAR STYLING
 * ================================================
 */


.topbar {
  margin-left: 300px;
  transition: margin-left .2s;
}

.project_name, .username {
  transition: font-size .2s;
}

.project_name {
  color: #F76902;
  font-size: 22.5px;
  padding-top: 2px;
  margin-left: 20px;
  margin-bottom: 0px;
}

.username {
  margin-top: 20px;
  margin-right: 2em;
  font-size: 20px;
  margin-bottom: 17px;
}

.logout_button {
  text-decoration: none;
  color: #F76902;
}

/*
 * ================================================
 *                   STATUS BAR
 *               (GREY THING AT TOP)
 * ================================================
 */

.status_bar {
  background-color: #DCDDDD;
  height: 80px;
}

.status_title, .purchase_id, .purchase_status {
  display: inline-block;
}

.status_title {
  padding-top: 25px;
  margin-left: 20px;
  margin-top: 0;
  font-size: 22.5px;
  font-weight: bold;
}

.status_break {
  width: 60%;
  display: none;
}

.purchase_id, .purchase_status {
  margin-left: 50px;
  font-size: 15px
}

.purchase_id {
  font-weight: lighter;
}

.purchase_status {
  color: #37b042;
  font-weight: bold;
}

/*
 * ================================================
 *                      FORM
 * ================================================
 */

.default_form {
  margin-left: 300px;
  transition: margin-left .2s;
}

.form_label {
  font-weight: bolder;
  font-size: 22.5px;
  position: relative;
  top: 20px;
  left: 45px;
}

.form_contents {
  margin-left: 90px;
  margin-bottom: 100px;
  top: 40px;
  position: relative;
}

.form_row {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
}

.first_form_row {
  margin-top: 0;
}

/*
 * ================================================
 *                   FORM FIELDS 
 * ================================================
 */

.flex_input {
  flex: 1 1 auto;
}

.input_label {
  margin-right: 20px;
}

.textfield {
  height: 30px;
  width: 200px;
  display: inline-block;
}

.dotted {
  border: 2px dotted black;
}

#purchaserNotes {
  width: 880px;
  height: 100px;
  margin-top: 20px;
  resize: none;
}

.radio {
  margin-right: 5px;
  margin-left: 10px;
}

.dropdown_select {
  border-color: black;
  width: 150px;
  height: 30px;
  display: inline;
}

.upload_body {
  text-align: center;
}

.upload_receipt_item {
  display: flex;
  margin-top: 10px;
}

.upload_x_button {
  padding-top: 7px;
}

.grid_label { 
  font-size: 20px;
  font-weight: bold;
}

.grid {
  margin-bottom: 10px;
}

.grid_field {
  width: 80%;
}

.tax_grid {
  display: block;
}

.column_header {
  color: white;
}

.grid_entry {
  height: 60px;
  padding-top: 16px;
  text-align: center;
  font-size: 14px;
}

.checkbox {
  transform: scale(1.5);
  margin-left: 20px;
  margin-right: 10px;
}

/*
 * ================================================
 *               FORM SUBMIT BUTTONS
 * ================================================
 */

.right_buttons { 
  float: right;
}

.btn {
  width: 100px;
  height: 35px;
  border-style: solid;
  font-size: 17px;
  margin-right: 10px;
  padding-top: 2px;
}

.upload_button {
  width: 25%;
}

.anti_button {
  color: #F76902; 
  border-color: #F76902;
  background-color: white;
}

.save_button {
  width: 120px;
}

.pro_button {
  color: white;
  background-color: #F76902;
  border-color: #F76902;
}

/*
 * ================================================
 *             TABLETS / PHONE SCREENS
 * ================================================
 */

@media only screen and (max-width: 900px) {
  #sidebar {
    width: 0
  }

  .sidebar_link {
    display: none;
  }

  .topbar, .default_form{
    width: 100%;
    margin-left: 0;
    text-align: left;
  }

  .navbar {
    height: 80px;
  }

  .project_name {
    margin-left: 20px;
  }

  .status_bar {
    height: 85px;
    text-align: center;
  }

  .status_title {
    width: 100%;
    margin-left: 0;
    margin-bottom: 0;
    padding-top: 10px;
  }

  .purchase_id, .purchase_status {
    margin-left: 0;
    margin-bottom: 0;
    margin-top: 10px;
  }

  .purchase_status {
    margin-left: 20px;
  }

}
